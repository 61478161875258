<template>
    <div class="feature-component" :style="{ backgroundColor: backgroundColor }">
      <div
        class="feature-container"
        :class="{ 'reverse-layout': reverse }"
      >
        <div class="image-container">
          <img :src="imageSrc" :alt="imageAlt" class="feature-image" />
        </div>
        <div class="text-container">
          <h2 class="infotitle">{{ title }}</h2>
          <div class="feature-description">
            <ul class="feature-list">
              <li v-for="(item, index) in listItems" :key="index">{{ item }}</li>
            </ul>
          </div>
          <button class="userbutton" @click="onTryClick">{{ buttonText }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Feature",
    props: {
      imageSrc: String,
      imageAlt: String,
      title: String,
      listItems: Array,
      backgroundColor: String,
      buttonText: {
        type: String,
        default: "Try",
      },
      reverse: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onTryClick() {
        this.$emit("tryClick");
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .feature-component {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .feature-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
  
    &.reverse-layout {
      flex-direction: row-reverse;
    }
  }
  
  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .feature-image {
    width: 100%;
    max-width: 400px;
    object-fit: cover;
  }
  
  .text-container {
    flex: 1;
    text-align: left;
  }
  
  .infotitle {
    font-size: 28px;
    color: #000;
  }
  
  .feature-list {
    color: black;
    padding: 0;
    list-style: none;
  }
  
  .userbutton {
    border-radius: 10px;
    padding: 8px 16px;
    background-color: rgb(60, 58, 230);
    color: white;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .userbutton:hover {
    background-color: #aa9595;
  }
  </style>
  