<template>
  <div>
    <main class="relative" id="primary-content">
      <div class="feature-wrapper">
      <Feature
        imageSrc="https://images-1255367272.cos.ap-chengdu.myqcloud.com/newgoaltree.png"
        imageAlt="目标树帮你梳理你的思路，组织你的项目与任务清单"
        :title="$t('goaltreetext')"
        :listItems="[$t('goaltreefun1'), $t('goaltreefun2')]"
        backgroundColor="#F9FAFB"
        :buttonText="$t('try')"
        @tryClick="showusersystemfunc"
      />

      <Feature
        imageSrc="https://images-1255367272.cos.ap-chengdu.myqcloud.com/tasktree.png"
        imageAlt="任务树帮助你不断拆解你的复杂任务，克服情绪上的障碍"
        :title="$t('tasktreetext')"
        :listItems="[$t('tasktreefun1'), $t('tasktreefun2'), $t('tasktreefun3'), $t('tasktreefun4')]"
        backgroundColor="#F2F6FC"
        :buttonText="$t('try')"
      
        @tryClick="showusersystemfunc"
      />

      <Feature
        imageSrc="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/evasyslarge.png"
        imageAlt="评价算法帮助你获得实时反馈，获得奇妙的心流体验"
        :title="$t('evasystext')"
        :listItems="[$t('evasysfunc1'), $t('evasysfunc2'), $t('evasysfunc3'), $t('evasysfunc4')]"
        backgroundColor="#F3F8F2"
        :buttonText="$t('try')"
        @tryClick="showusersystemfunc"
      />

      <!-- Privacy Feature -->
      <Feature
        imageSrc="https://images-1255367272.cos.ap-chengdu.myqcloud.com/privacy.jpg"
        imageAlt="我们关注你的隐私，绝不泄露，对商业广告说NO!"
        :title="$t('privacytext')"
        :listItems="[$t('privacyfunc1'), $t('privacyfunc2'), $t('privacyfunc3')]"
        backgroundColor=#FFFBF2
        :buttonText="$t('try')"
     
        @tryClick="showusersystemfunc"
      />

      <!-- Recommendation Algorithm Feature -->
      <Feature
        imageSrc="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/recommendalgorithm.jpg"
        imageAlt="评价算法帮助你获得实时反馈，获得奇妙的心流体验"
        :title="$t('recmtext')"
        :listItems="[$t('recmfunc1'), $t('recmfunc2'), $t('recmfunc3')]"
        backgroundColor=#EAF4F4
        :buttonText="$t('try')"
        @tryClick="showusersystemfunc"
      />

      <!-- Diary Feature -->
      <Feature
        imageSrc="https://rongbrain-1255367272.cos.ap-chengdu.myqcloud.com/storybook.png"
        imageAlt="记录和追踪你的成就和进展"
        :title="$t('dairytext')"
       
        :listItems="[$t('dairyfunc1'), $t('dairyfunc2'), $t('dairyfunc3')]"
        backgroundColor="#FFFFFF"
        :buttonText="$t('try')"
        @tryClick="showusersystemfunc"
      />
      <!-- Repeat Feature component for other sections -->
    </div>
      <div class="adzone">
        <h3 class="font-bold text-center " data-aos="fade-up">
          atomTask已经帮助用户解决
          <br />
          <span class="adnum">40,000+</span> 的日常任务，共在任务上投入<span class="adnum1">23360+</span> 小时
        </h3>
        <p class="introtext " data-aos="fade-up">{{ $t('declaim') }}</p>
      </div>
    </main>
  </div>
</template>

<script>
import Feature from "./feature.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProductIntro",
  components: {
    Feature,
  },
  methods: {
    showusersystemfunc() {
      this.showusersystem = true;
      this.$emit("setTime", new Date().getTime());
    },
  },
});
</script>

<style scoped lang="scss">
.feature-wrapper {
  padding-left: 3.8%;
  padding-right: 3.8%;
}
.adzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .font-bold {
    font-weight: 700;
    font-size: 40px;
  }

  .introtext {
    color: rgb(54 61 78);
  }
}

.adnum {
  color: red;
}

.adnum1 {
  color: blue;
}
</style>
